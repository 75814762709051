import React from "react";
import { Button, Modal } from 'antd';

const ElSubscription = () => {
    return(
    <>
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </>
    )
}

export default ElSubscription;