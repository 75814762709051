import React from "react";
import axios from 'axios';


const $api = axios.create(
    {
        withCredentials: true,
        baseURL: 'http://localhost:5000/api/'
    }
)

const BackRequest = async (url, body) => {
    const res = $api.post("user/login", body).then((e) => console.log(e))
}

// const BackRequest = (url, body) => {
//     const TARGET_URL = BASE_URL + url
//     fetch(TARGET_URL,
//         {
//             method: 'POST',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//             },
//             body: body
//         }).then((e) => {console.log(e)})
// }

export default BackRequest;