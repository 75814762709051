import React from "react";
import styles from './Home.module.css';

const ElHome = () => {

    return(
        <div className={styles.Home}>
            <div className={styles.Home__Left}>
                <div className={styles.ScoreTable}></div>
                <div className={styles.Servers}></div>
            </div>
            <div className={styles.Home__Right}>
                <div className={styles.Home__Right__High}>
                    <div className={styles.Events}></div>
                    <div className={styles.Matches}></div>
                </div>
                <div className={styles.Discord}></div>
            </div>
        </div>
    )
}

export default ElHome;