import React from "react";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const LottieLoader = () => {
    return(
        <Player
            autoplay
            loop
            src="https://assets10.lottiefiles.com/packages/lf20_GWWy4D.json"
            style={{ height: '700px', width: '700px' }}
            >
        </Player>   
    )
}

export default LottieLoader;