import React from "react";
import './loader.css';

const LoaderMiniMap = () => {
    return(
        <div className='MiniMap'>
            <div className='Gates'>
                <div className='RedGates'></div>
                <div className='BlueGates'></div>
            </div>
            <div className='Movable'>
                <div className='Puck'></div>
                <div className='Player'></div>
            </div>
        </div>
    )
}

export default LoaderMiniMap;