import { Routes, Route, useLocation} from 'react-router-dom';

import ElHeader from './components/Header/Header';

import Login from './components/Login/Login';
import Signup from './components/SignUp/Signup';
import PreSignup from './components/PreSignUp/PreSignup';
import ElHome from './components/Home/Home';

import './App.css';
import Profile from './components/Profile/Profile';
import ElSubscription from './components/Subscription/Subscription';
import Terms from './components/Terms/terms';

function App() {
  const location = useLocation();
  if(location.pathname == '/presignup'){
    return (
      <div id='app'>
        <PreSignup />
      </div>
    )
  } else{
  return (
      <div id='app'>
        <ElHeader />
        <div className='content'>
          <Routes>
            <Route exact path="/" element={<ElHome />} />
            <Route exact path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/subscription" element={<ElSubscription />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </div>
      </div>
  );
  }
}

export default App;