import React, { useState } from "react";
import { Modal } from "antd";
import './terms.css';

const Terms = () => {
    return(
        <div className={"UserAgreement"}>
            <p style={{ textAlign: "center" }}>
                <strong>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ ДЛЯ ВЕБ-САЙТА</strong>
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</strong>
            </p>
            <p>
                В тексте настоящего Соглашения нижеизложенным терминам дано следующее
                значение:
            </p>
            <p>
                "<strong>Сайт</strong>" означает следующий сайт:{" "}
                <span style={{ textAlign: "center" }}>
                <span id="span_id_site_name">HQM Ranked</span>, который принадлежит
                Компании и находится по следующей ссылке:{" "}
                </span>
                <span id="span_id_линк_сайт">https://hqmranked.ru</span>.
            </p>
            <p>
                "<strong>Пользователь</strong>", "<strong>Вы</strong>", "
                <strong>Ваш</strong>", "<strong>Вас</strong>", "<strong>Вами</strong>" или
                какие-либо другие схожие производные (в зависимости от контекста) означает
                лицо, которое <strong>(1)</strong> пользуется Сайтом и/или получило доступ к
                его Контенту; и <strong>(2)</strong> дало свое согласие на соблюдение правил
                пользования Сайтом, изложенных в тексте данного Соглашения, путем
                использования данного Сайта.
            </p>
            <p>
                "<strong>Компания</strong>", "<strong>Мы</strong>", "<strong>Наш</strong>",
                "<strong>Нас</strong>", "<strong>Нами</strong>" или какие-либо другие схожие
                производные (в зависимости от контекста) означает следующее лицо:{" "}
                <span id="span_id_компания">ДЖАМИЕВ ДАВИД МЕТЛЕБОВИЧ</span>, адрес
                местонахождения:{" "}
                <span id="span_id_адрес_компании">
                Россия, г. Москва, ул. Амурская, 1Ак5
                </span>{" "}
                (включая, его филиалы и представительства как на территории Российской
                Федерации, так и за ее пределами, а также какие-либо другие лица, созданные
                в результате реорганизации Компании), которое является владельцем Сайта или
                им управляет.
            </p>
            <p>
                "<strong>Контент сайта</strong>" означает все объекты, размещенные Компанией
                и/или третьими лицами (с разрешения Компании) на Сайте, в том числе элементы
                дизайна, текст, графические изображения, иллюстрации, видео, программы,
                музыка, звуки, информация, уведомления и какие-либо другие объекты схожего
                назначения, их подборки или комбинации.
            </p>
            <p>
                "<strong>ПО сайта</strong>" означает программное обеспечение, разработанное
                Компанией (и/или третьими лицами по поручению Компании) для Сайта, включая,
                однако не ограничиваясь, весь софт, скрипты, коды (HTML коды), программы и
                тп.
            </p>
            <p>
                "<strong>Услуги</strong>" означает совокупно Контент сайта и ПО сайта.
            </p>
            <p>
                "<strong>Пользовательский контент</strong>" означает<strong> (1)</strong>{" "}
                все публикации, совершенные Пользователем на Сайте, включая, однако не
                ограничиваясь, комментарии; оценки; отзывы; отчеты; фидбеки; размещенные
                видео, фотографии, музыка и другие медиа файлы; проставленные лайки;
                рейтинги и/или какие-либо другие формы активности, доступные Пользователю на
                Сайте, а также<strong> (2)</strong> какой-либо другой контент, создаваемый
                Пользователем.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>2. ПРИСОЕДИНЕНИЕ К СОГЛАШЕНИЮ</strong>
            </p>
            <p>2.1. Пользователи используют Сайт для следующих целей:</p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_услуги_на_сайте">
                    Получение информации по рейтинговым играм от платформы HQM Ranked в игре
                    Hockey?
                </span>
                </em>
            </p>
            <p>
                2.2. Настоящее Пользовательское соглашение (далее "
                <strong>Соглашение</strong>") определяет правила и порядок использования
                Сайта и Услуг, права и обязанности Пользователей, а также регулирует
                поведение Пользователей при получении доступа к Сайту и Услугам.
            </p>
            <p>
                2.3. Пользователь принимает условия данного Соглашения посредством
                продолжения использования Сайта.
            </p>
            <p>
                2.4. Данное Соглашение является обязательным для его сторон (т.е. для
                Компании и Пользователя). Уступка Пользователем своих прав по данному
                Соглашению возможна только после получения предварительного письменного
                согласия от Компании.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>3. ПОЛЬЗОВАТЕЛИ САЙТА</strong>
            </p>
            <p>
                <strong>Общие критерии и возраст</strong>
            </p>
            <p>
                3.1. Для использования Сайта Пользователи должны соответствовать следующим
                критериям (совокупно):
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(1)</strong> быть не младше{" "}
                <span id="span_id_допустимый_возраст">14</span>-лет; и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(2)</strong> не быть ограниченным в праве доступа к Сайту и Услугам
                на основании решения суда, вступившего в законную силу, или в случаях,
                предусмотренных действующим законодательством или условиями данного
                Соглашения.
            </p>
            <p>
                <strong>Регистрация на сайте и создание личного аккаунта</strong>
            </p>
            <p>
                3.2. Использование данного Сайта возможно как Пользователями, которые прошли
                процедуру бесплатной регистрации и создали свой личный аккаунт (кабинет),
                так и Пользователями, которые отказались от такой регистрации.
                Незарегистрированные Пользователи не имеют доступа к следующим Услугам:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_функционал_сайта">
                    (1) Возможность управлять профилем игрока
                    <br />
                    (2) Возможность делать покупки
                </span>
                </em>
            </p>
            <p>
                3.3. Для снятия указанных ограничений и получения полного доступа
                Пользователю необходимо пройти регистрацию на Сайте. По результатам
                завершения регистрации Пользователь получает уникальный логин и пароль.
            </p>
            <p>
                3.4. Действие данного Соглашения распространяется одинаково в полном объеме
                (без каких-либо исключений) как на зарегистрированных, так и на
                незарегистрированных Пользователей.
            </p>
            <p>
                3.5. Для регистрации Пользователю необходимо предоставить следующею
                информацию о себе:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_информация_при_регистрации">
                    ФИО, дата рождения, страна, электронный адрес
                </span>
                </em>
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>4. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</strong>
            </p>
            <p>
                4.1. Компании принадлежат все без исключения права собственности, включая
                имущественные права интеллектуальной собственности, на весь Контент сайта, а
                также ПО сайта. ПО сайта и Контент сайта защищены авторским правом в
                порядке, предусмотренном действующим гражданским законодательством
                Российской Федерации, а также международными договорами и конвенциями в
                области защиты объектов интеллектуальной собственности.
            </p>
            <p>
                4.2. Пользователям запрещено копировать, воспроизводить, модифицировать,
                компилировать, распространять, отображать в какой-либо форме, публиковать,
                скачивать, передавать, продавать или иным способом распространять или
                использовать Контент сайта и ПО сайта, кроме случаев, когда такие действия
                ПРЯМО разрешены условиями данного Соглашения или действующим
                законодательством Российской Федерации.
            </p>
            <p>
                4.3. Ничто в тексте данного Соглашения не может быть истолковано как
                передача Пользователю каких-либо исключительных прав на Контент сайта
                (полностью или в отдельной части) и/или ПО сайта.
            </p>
            <p>
                4.4. Компания владеет всеми правами в отношении торговых марок, коммерческих
                (бизнес) наименований, брендов, логотипов, зарегистрированных на ее имя
                (далее "<strong>Торговые марки"</strong>). Такие Торговые марки защищаются
                действующим законодательством и НИЧТО в тексте данного Соглашения не может
                быть истолковано как передача какой-либо лицензии Пользователю на
                использование таких Торговых марок.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>5. ПОРЯДОК РАБОТЫ С САЙТОМ</strong>
            </p>
            <p>
                <strong>Правила поведения на сайте</strong>
            </p>
            <p>
                5.1. Во время использования Сайта Пользователь обязуется придерживаться
                следующих правил:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(1)</strong> соблюдать все обязательства, взятые на себя
                Пользователем в связи с присоединением к данному Соглашению; и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(2)</strong> предоставлять достоверные о себе данные во время
                регистрации на Сайте и для создания личного аккаунта (кабинета); и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(3)</strong> не выдавать себя за какое-либо другое лицо, включая,
                однако не ограничиваясь, не предоставлять какие-либо данные третьих лиц (без
                получения от них прямого, предварительного и осознанного согласия) для
                регистрации на Сайте и/или для создания личного аккаунта (кабинета); и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(4)</strong> информировать Компанию о хищении логинов, паролей или
                каких-либо других ключей доступа Пользователя к личному аккаунту (кабинету);
                и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(5)</strong> не предоставлять третьим лицам доступ к своему аккаунту
                (кабинету) и/или логины, пароли или другие ключи доступа; и{" "}
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(6)</strong> не загружать, не хранить, не публиковать, не
                распространять, не размещать, не рекламировать, не отправлять, не
                предоставлять доступ или иным образом не использовать Пользовательский
                контент, который<strong> (а)</strong> содержит угрозы, дискредитирует,
                оскорбляет, порочит честь и достоинство или деловую репутацию или нарушает
                неприкосновенность частной жизни других Пользователей или третьих лиц; и{" "}
                <strong>(б)</strong> является спамом, буллингом, вульгарным или
                непристойным, содержит порнографические изображения и тексты, сцены
                сексуального характера, в том числе с участием несовершеннолетних, или сцены
                насилия, включая сексуальное, над людьми или животными; и{" "}
                <strong>(в)</strong> содержит какие-либо формы подстрекательства к суициду
                и/или пропагандирует или способствует разжиганию расовой, религиозной,
                этнической ненависти или вражды, пропагандирует фашизм или идеологию
                расового превосходства, или содержит экстремистские материалы; и{" "}
                <strong>(г)</strong> пропагандирует нарушение прав или законных интересов
                других Пользователей или третьих лиц или пропагандирует преступление или
                содержит советы/руководства/инструкции по его совершению; и
                <strong> (д)</strong> нарушает иные правила данного Соглашения или является
                запрещенным на основании действующего законодательства; и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(7)</strong> не совершать какие-либо действия (с использованием
                средств автоматизации или без таких средств), направленные на сбор
                каких-либо персональных данных других Пользователей; и
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(8)</strong> не предпринимать какие-либо действия и не помогать
                третьим лицам в совершении действий, направленных на подрыв работы Сайта,
                включая, однако не ограничиваясь, <strong>(а)</strong> загружать вирусы или
                вредоносный код;<strong> (б)</strong> совершать действия, которые могут
                привести к отключению Сайта, к нарушению нормальной работы Сайта или ПО
                сайта, или к ухудшению внешнего вида Сайта и/или Контента Сайта.
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>(9)</strong> не предпринимать какие-либо иные действия, которые
                являются незаконными, мошенническими, дискриминационными или вводящими в
                заблуждение.
            </p>
            <p>
                <strong>Права пользователей на размещенный контент</strong>
            </p>
            <p>
                5.2. Пользовательский контент имеют право создавать только
                зарегистрированные пользователи.
            </p>
            <p>
                5.3. Создаваемый Вами Пользовательский контент является объектом
                интеллектуальной собственности, защищается действующим законодательством, в
                связи с чем Компания не претендует на получение и не требует от Вас
                предоставления ей каких-либо прав собственности на Ваш Пользовательский
                контент. Ничто в тексте настоящего Соглашения не должно быть истолковано как
                лишение Пользователя прав на создаваемый им Пользовательский контент или их
                ограничение.
            </p>
            <p>
                5.4. Вместе с этим Вы предоставляете Компании неисключительную, не требующую
                лицензионных отчислений, действующую по всему миру лицензию (далее "
                <strong>Лицензия</strong>") с правом передачи и выдачи сублицензий на
                хранение, использование, распространение, изменение, запуск, копирование,
                публичное исполнение или показ, перевод Вашего Пользовательского контента и
                создание производных работ на его основе.
            </p>
            <p>
                5.5. Действие выданной Вами таким образом Лицензии автоматически
                прекращается в случае удаления Вашего личного аккаунта (кабинета).
            </p>
            <p>
                5.6. Компания обязуется предпринять все возможные действия, направленные на
                полное удаление Вашего Пользовательского контента сразу после наступления
                обстоятельств для прекращения Лицензии, за исключение следующих случаев
                когда:<strong> (1)</strong> часть Вашего Пользовательского контента была
                использована другими Пользователями (на основании выданной Вами ранее
                Лицензии, в таком случае Ваш Пользовательский контент будет доступным до тех
                пор на Сайте и другим Пользователям, пока другой Пользователь не совершит
                его удаление); или <strong>(2)</strong> Пользовательский контент,
                размещенный Вами, является доказательством какого-либо нарушения, проступка
                или преступления в уголовном, административном или гражданском производстве,
                или его последующее хранение требуется на основании требований действующего
                законодательства или полученного запроса от компетентного государственного
                органа; или <strong>(3)</strong> в других случаях, указанных в данном
                Соглашении.
            </p>
            <p>
                <strong>Требование к пользовательскому контенту</strong>
            </p>
            <p>
                5.7. Пользователям запрещается загружать какой-либо Пользовательский
                контент, который может принадлежать третьим лицам или права на использование
                которого не были в необходимом объеме предоставлены такому Пользователю.
                Настоящим Пользователь берет на себя обязанность возместить Компании ВСЕ
                УБЫТКИ И СУДЕБНЫЕ ИЗДЕРЖКИ, КОТОРЫЕ БЫЛИ ЕЙ ПОНЕСЕНЫ В СВЯЗИ С ПРЕДЪЯВЛЕНИЕМ
                ИСКОВ ТРЕТЬИМИ ЛИЦАМИ О ТОМ, ЧТО ОПУБЛИКОВАННЫЙ ПОЛЬЗОВАТЕЛЬСКИЙ КОНТЕНТ
                НАРУШАЕТ ПРАВА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ ТАКИХ ТРЕТЬИХ ЛИЦ.
            </p>
            <p>
                5.8. Компания не проверяет и не может проверить все материалы, публикуемые
                Пользователями в рамках создаваемого ими Пользовательского контента, в связи
                с чем Компания не несет ответственности за содержание такого
                Пользовательского контента, за его использование и/или его воздействие на
                третьих лиц или других Пользователей. Управление Сайтом не означает, что
                Компания подтверждает, поддерживает, гарантирует, распространяет и/или верит
                в информацию, размещаемую в рамках Пользовательского контента. Пользователь
                является ответственным за свою собственную защиту и защиту своего устройства
                от вирусов и прочего вредоносного ПО. Компания не берет на себя какую-либо
                ответственность за вред, причиненный в результате использования Сайта, его
                Услуг и/или Пользовательского контента (включая его загрузку).
            </p>
            <p>
                5.9. Компания имеет право в любой момент проверить Пользовательский контент
                на предмет его соответствия требованиям данного Соглашения или действующему
                законодательству. Вместе с этим ничто в тексте данного Соглашения не должно
                быть истолковано как прямая обязанность Компании проводить какую-либо
                самостоятельную проверку Пользовательского контента не иначе, как по запросу
                других Пользователей или третьих лиц.
            </p>
            <p>
                5.10. Если Вы столкнулись с Пользовательским контентом, который нарушает
                условия данного Соглашения или законные права и интересы Пользователей или
                третьих лиц, Вы можете направить свою жалобу следующим образом:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_жалоба_на_контент">help@hqmranked.ru</span>
                </em>
            </p>
            <p>
                5.11. С целью исполнения требований ч. 1 статьи 10-6 Федерального закона "Об
                информации, информационных технологиях и о защите информации" Компания имеет
                право проводить внутренний мониторинг Пользовательского контента с целью
                соблюдения Пользователями ограничений, изложенных в Федеральном законе "Об
                информации, информационных технологиях и о защите информации".
            </p>
            <p>
                5.12. Если будет обнаружено, что Пользовательский контент нарушает условия
                данного Соглашения или положения действующего законодательства, Компания
                имеет право по своему собственному усмотрению, в любой момент, без
                необходимости предупреждения Пользователя и взятия на себя какой-либо
                ответственности в будущем удалить такой Пользовательский контент вовсе, а в
                случае повторного нарушения - удалить Ваш личный аккаунт (кабинет).
            </p>
            <p>
                <strong>Отзывы о работе сайта</strong>
            </p>
            <p>
                5.13. Каждый Пользователь время от времени имеет право (но не обязанность)
                оставлять или направлять свои идеи, отзывы, предложения или проекты,
                направленные на улучшение работы Сайта или качества предоставляемых Услуг.
                Такие отзывы могут быть направлены Пользователем следующим способом:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_направление_отзыва">help@hqmranked.ru</span>
                </em>
            </p>
            <p>
                5.14. В случае направления такой идеи, отзыва, предложения или проекта
                Пользователь автоматически предоставляет нам неисключительную, не требующую
                лицензионных отчислений, действующую по всему миру лицензию с правом
                передачи и выдачи сублицензий на хранение, использование, распространение,
                изменение, запуск, копирование, публичное исполнение или показ, перевод
                Ваших идей, отзывов, предложений или проектов, а также создание производных
                работ на их основе.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ НА САЙТЕ</strong>
            </p>
            <p>
                <strong>Размещение рекламы компанией</strong>
            </p>
            <p>
                6.1. Компания имеет право время от времени размещать на Сайте какие-либо
                рекламные или маркетинговые материалы.{" "}
            </p>
            <p>
                6.2. Пользователь может время от времени получать сообщения от Компании с
                определенными рекламными или маркетинговыми материалами. Отправка Компанией
                указанных материалов осуществляется только с согласия Пользователя,
                выраженной в виде оформления соответствующей подписки посредством выполнения
                следующих действий:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_оформление_подписки">
                    В настройках личного кабинета поставить галочку напротив "Получать
                    рекламную рассылку"
                </span>
                </em>
            </p>
            <p>
                6.3. Пользователь также имеет право в любой момент и без указания причины
                отказаться от такой бесплатной подписки посредством выполнения следующих
                действий:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_отказ_от_подписки">
                    В настройках личного кабинета убрать галочку напротив "Получать
                    рекламную рассылку"
                </span>
                </em>
            </p>
            <p>
                <strong>Размещение рекламы третьими лицами</strong>
            </p>
            <p>
                6.4. Контент сайта может содержать ссылки на веб-сайты третьих лиц и/или
                рекламные или маркетинговые материалы о товарах/услугах, предоставленные
                такими третьими лицами (далее "<strong>Реклама третьих лиц</strong>").
                КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО ОТВЕТСТВЕННОСТЬ <strong>(1)</strong> ЗА
                СОДЕРЖАНИЕ РЕКЛАМЫ ТРЕТЬИХ ЛИЦ, А ТАКЖЕ ЗА НАЛИЧИЕ, КАЧЕСТВО И БЕЗОПАСНОСТЬ
                ПРОДВИГАЕМЫХ ТОВАРОВ/УСЛУГ В ТАКОЙ РЕКЛАМЕ; и <strong>(2)</strong> ЗА
                КАКИЕ-ЛИБО УБЫТКИ, ПОТЕРИ ИЛИ УЩЕРБ, ПОНЕСЕННЫЕ ИЛИ ПРИЧИНЕННЫЕ ПОЛЬЗОВАТЕЛЮ
                В РЕЗУЛЬТАТЕ ПРОЧТЕНИЯ ИМ ТАКОЙ РЕКЛАМЫ, ИСПОЛЬЗОВАНИЯ ИМ ПРОДВИГАЕМЫХ В
                РЕКЛАМЕ ТРЕТЬИХ ЛИЦ ТОВАРОВ/УСЛУГ.
            </p>
            <p>
                6.5. В случае перехода на другой сайт через размещенную на Сайте Рекламу
                третьих лиц, Компания не может гарантировать, что такой веб-сайт является
                безопасным для Пользователя и/или его компьютера. Ни что в тексте данного
                Соглашения не должно быть истолковано как заверение, поощрение, рекомендация
                или побуждение Пользователя воспользоваться Рекламой третьих лиц, посетить
                какие-либо сайты третьих лиц, а также попробовать, приобрести,
                воспользоваться какими-либо товарами/услугами третьих лиц. <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>7. СОВЕРШЕНИЕ ПОКУПОК ЧЕРЕЗ САЙТ</strong>
            </p>
            <p>
                <strong>Общие положения</strong>
            </p>
            <p>
                7.1. Пользователи имеют возможность приобретать определенные товары/услуги
                на Сайте. Продавцом таких товаров/услуг может выступать как сама Компания,
                так и третьи лица (партнеры, продавцы, дистрибьюторы) (далее "
                <strong>Поставщики</strong>") - в зависимости от того, что указано в
                описании к тому или иному товару/услуге.
            </p>
            <p>
                7.2. ПРЕДУПРЕЖДЕНИЕ ПРИ ПОКУПКЕ ТОВАРОВ/УСЛУГ ОТ ПОСТАВЩИКОВ.
                Ответственность за исполнение договора купли-продажи, заключенного между
                Пользователем и Поставщиком через Сайт на основании предоставленной
                Компанией информации о товаре/услуге или Поставщике, а также за соблюдение
                прав потребителей, нарушенных в результате передачи потребителю
                товара/услуги ненадлежащего качества и обмена непродовольственного товара
                надлежащего качества на аналогичный товар, несет Поставщик.
            </p>
            <p>
                7.3. Компания не хранит какие-либо данные о Вашей дебетовой или кредитной
                карте, используемой для оплаты.
            </p>
            <p>
                7.4. КОМПАНИЯ ПРЕДОСТАВЛЯЕТ ЗАКАЗАННЫЙ ТОВАР/УСЛУГУ ТОЛЬКО ПОСЛЕ ПОЛУЧЕНИЯ
                ОПЛАТЫ В ПОЛНОМ ОБЪЕМЕ.
            </p>
            <p>
                7.5. Если Компания не может предоставить Вам заказанный и оплаченный
                товар/услугу, Компания обязуется незамедлительно провести возврат Вам всей
                уплаченной ранее суммы в счет погашения стоимости заказанного товара/услуги
                (за исключением банковских комиссий и издержек, которые могли быть начислены
                Вашим обслуживающим банком при оплате товаров/услуг через Сайт).
            </p>
            <p>
                7.6. Компания не гарантирует наличие того или иного товара/услуги на Сайте.
            </p>
            <p>
                <strong>Способы и сроки доставки</strong>
            </p>
            <p>
                7.7. Компания предоставляет следующие способы доставки заказанных на Сайте
                товаров/услуг:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_способы_доставки">
                    (1) получение результатов дистанционно (в игре)
                    <br />
                    (2) получение в ближайшем почтовом отделении (для физических товаров)
                </span>
                </em>
            </p>
            <p>
                7.8. На стоимость заказанного Вами товара/услуги могут дополнительно
                начисляться какие-либо импортные (ввозные) налоги, сборы или платежи (к
                примеру, НДС) в стране, в которую осуществляется такая доставка. Компания не
                уплачивает от имени Пользователей указанные импортные налоги, сборы или
                платежи, не может предсказать нужно ли их будет уплатить, а также
                предполагаемые суммы таких начислений.
            </p>
            <p>
                7.9. Пользователь имеет право предъявить Компании требование о возврате
                уплаченных им ранее денежных средств за товар/услугу, если такой
                товар/услуга не были получены Пользователем в согласованный срок. В таком
                случае Компания обязуется в течение <em>10 (десяти) календарных дней</em>{" "}
                осуществить возврат такой суммы в полном объеме.
            </p>
            <p>
                7.10. Компания не берет на себя какую-либо ответственность за ошибку в
                доставке, которая произошла по вине Пользователя, который, к примеру, не
                указал верные данные получателя.
            </p>
            <p>
                <strong>Порядок оплаты</strong>
            </p>
            <p>
                7.11. Стоимость товара/услуги на Сайте указывается в российских рублях.
                Оплата за товар/услугу также должна быть проведена в российских рублях.
            </p>
            <p>
                7.12. Если у Вас нету счета в указанной валюте, в таком случае Вы можете
                воспользоваться Вашей дебетовой или кредитной картой, а обслуживающий банк
                (держатель дебетовой или кредитной карты) проведет соответствующею
                конвертацию по обменному курсу согласно его внутрибанковским правилам.
                Компания не отвечает за обменный курс, используемый Вашим обслуживающим
                банком при такой конвертации.
            </p>
            <p>7.13. Пользователь может провести оплату одним из следующих способов:</p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_способы_оплаты">
                    (1) Банковской картой
                    <br />
                    (2) СБП
                </span>
                </em>
            </p>
            <p>
                7.14. Обращаем внимание, что Компания может в любой момент отказать в приеме
                того или иного способа платежа без какого-либо объяснения или уведомления
                Пользователей.
            </p>
            <p>
                7.15. Товар/услуга считается оплаченным Пользователем в полном объеме с
                момента подтверждения исполнения такого платежа банковским учреждением,
                обслуживающим дебетовую или кредитную карту Пользователя, которая была им
                использована для оплаты на Сайте.
            </p>
            <p>
                7.16. Обращаем внимание, что Ваш обслуживающий банк может проводить по
                своему собственному усмотрению дополнительные проверки совершенной
                транзакции через наш Сайт, что в последствие может привести к задержке
                оплаты заказанного Вами ранее товара/услуги.
            </p>
            <p>
                7.17. Компания не берет на себя какую-либо ответственность за убытки, ущерб,
                упущенную выгоду, потерю деловой репутации, возникшие у Пользователя из-за
                задержки в оплате, что в свою очередь могло привести к задержке с нашей
                стороны в предоставлении Вам определенного товара/услуги.
            </p>
            <p>
                <strong>Порядок обмена и возврата товара</strong>
            </p>
            <p>
                7.18. Пользователь имеет право на обмен или возврат ранее приобретенного
                товара. Обмену и возврату не подлежат только те товары, которые указаны в
                Постановлении Правительства Российской Федерации от 31 декабря 2020 г. №
                2463 "Об утверждении Правил продажи товаров по договору розничной
                купли-продажи, перечня товаров длительного пользования, на которые не
                распространяется требование потребителя о безвозмездном предоставлении ему
                товара, обладающего этими же основными потребительскими свойствами, на
                период ремонта или замены такого товара, и перечня непродовольственных
                товаров надлежащего качества, не подлежащих обмену, а также о внесении
                изменений в некоторые акты Правительства Российской Федерации". Во всех
                остальных случаях Пользователь имеет право обратиться к Компании с
                требованием об обмене или возврате товара в порядке, предусмотренном
                действующим законодательством о защите прав потребителей, а также условиями
                данного Соглашения.
            </p>
            <p>
                7.19. Пользователь имеет право возвратить обратно или обменять товар
                надлежащего качества без указания причины в течение{" "}
                <em>14 (четырнадцати) календарных дней</em> с момента совершения его
                покупки. В случае возврата товара надлежащего качества Пользователю
                возвращаются в полном объеме все суммы, уплаченные им в счет погашения
                стоимости такого товара. Для обмена и возврата приобретенного товара
                Пользователю необходимо выполнить следующие действия:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_обмен_товара">
                    направить запрос по адресу help@hqmranked.ru
                </span>
                </em>
            </p>
            <p>
                <strong>Качества приобретаемого товара</strong>
            </p>
            <p>
                7.20. Сразу после получения товара на руки Пользователь обязан проверить его
                на наличие возможных дефектов или недостатков, а также на предмет
                соответствия характеристик товара описанию, изложенному на Сайте.
            </p>
            <p>
                7.21. Пользователь имеет право предъявить Компании требование о возврате
                уплаченных им ранее денежных средств за товар/услугу, если такой
                товар/услуга не соответствуют заявленным условиям качества товара или его
                описанию, изложенным на Сайте. В таком случае Компания обязуется в течение{" "}
                <em>10 (десяти) календарных дней</em> осуществить возврат такой суммы в
                полном объеме.
            </p>
            <p>
                7.22. Пользователь имеет право предъявить свои претензии к качеству
                приобретенного товара к Компании или Поставщикам (в зависимости от того чей
                товар приобретен) в течение установленного гарантийного срока/срока годности
                товара или, если такой срок не установлен, в течение <em>2 (двух) лет</em>{" "}
                от момента совершения покупки такого товара.
            </p>
            <p>
                <strong>Акции на сайте</strong>
            </p>
            <p>
                7.23. Время от времени Компания может размещать на Сайте какие-либо
                акционные предложения на товары/услуги. Периодичность размещения таких
                предложений, а также их условия определяются исключительно Компанией.
            </p>
            <p>
                7.24. Пользователь осведомлен, что количество акционных предложений на
                товары/услуги ограничено.
            </p>
            <p>
                7.25. Компания не гарантирует и не обещает Пользователям, что
                <strong> (1)</strong> приобретение каких-либо акционных товаров/услуг на
                Сайте является каким-либо образом выгодным для Пользователя и/или третьих
                лиц; и<strong> (2)</strong> стоимость акционных товаров/услуг должна быть в
                обязательном порядке ниже их обычной стоимости на Сайте или на других
                веб-сайтах третьих лиц.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>8. ОФОРМЛЕНИЕ ПОДПИСКИ НА САЙТЕ</strong>
            </p>
            <p>
                <strong>Общие положения</strong>
            </p>
            <p>8.1. По желанию Пользователь может оформить подписку на Сайте.</p>
            <p>
                8.2. Подписка может быть только ежемесячной. Подписка подлежит
                АВТОМАТИЧЕСКОМУ ОБНОВЛЕНИЮ ДО ТЕХ ПОР, ПОКА ПОЛЬЗОВАТЕЛЬ НЕ ОТКАЗАЛСЯ ОТ
                НЕЕ.
            </p>
            <p>
                8.3. Подписка является платной и предоставляется Пользователю только при
                условии ее предварительной полной оплаты. Если произошла просрочка оплаты
                подписки, доступ Пользователя к определенному контенту блокируется до
                момента проведения оплаты за подписку в полном объеме.
            </p>
            <p>
                <strong>Порядок оформления подписки</strong>
            </p>
            <p>
                8.4. Пользователь имеет право оформить подписку в любой момент посредством
                выполнения следующих действий:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_оформление_подписки_2">
                    (1) Перейдя в раздел "Подписка"
                    <br />
                    (2) В боте Telegram перейдя по команде /subscription
                </span>
                </em>
            </p>
            <p>
                8.5. Оплата подписки должна быть проведена в валюте, указанной на Сайте.
                Стоимость подписки указывается с учетом НДС, а также иных обязательных
                налогов и сборов.
            </p>
            <p>
                8.6. Пользователь имеет возможность оплаты подписки одним из следующих
                способов:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_способы_оплаты_подписки">
                    (1) Банковской картой
                    <br />
                    (2) СБП
                </span>
                </em>
            </p>
            <p>
                8.7. В случае отмены уже оплаченной подписки, Пользователь не имеет права
                требовать какого-либо пропорционального или полного возмещения ранее
                уплаченных сумм за подписку и продолжает пользоваться контентом до истечения
                срока действия оплаченного периода подписки.
            </p>
            <p>
                <strong>Порядок прекращения подписки</strong>
            </p>
            <p>
                8.8. Пользователь имеет право в любой момент отказаться от подписки
                посредством выполнения следующих действий:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_оформление_подписки_2">
                    (1) Перейдя в раздел "Подписка"
                    <br />
                    (2) В боте Telegram перейдя по команде /subscription
                </span>
                </em>
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>9. ПРЕКРАЩЕНИЕ ДОСТУПА К САЙТУ</strong>
            </p>
            <p>
                9.1. Пользователь имеет право в любой момент прекратить использование Сайта
                посредством удаления своего аккаунта:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_удаление_аккаунта">
                    (1) Перейдя в личный кабинет в раздел "Управление аккаунтом"
                </span>
                </em>
            </p>
            <p>
                9.2. В случае <strong>(1)</strong> нарушения Пользователем условий
                настоящего Соглашения; и/или<strong> (2)</strong> нарушения прав
                интеллектуальной собственности Компании, других Пользователей или третьих
                лиц; и/или <strong>(3)</strong> совершения действий, которые являются
                противозаконными, нарушают права и интересы Компании, других Пользователей
                или третьих лиц или подрывают работу Сайта или возможность использования
                Сайта другими Пользователями; и/или <strong>(4)</strong> Услуги или Сайт
                используется Пользователем таким образом, что это может повлечь юридическую
                ответственность Компании в будущем; и/или<strong> (5)</strong> если этого
                требует действующее законодательство или компетентный государственный орган,
                Компания имеет право без предварительного уведомления в любой момент
                прекратить (остановить) доступ Пользователя к Сайту и его Услугам.
            </p>
            <p>
                9.3. Такое прекращение доступа кроме прочего также подразумевает удаление
                личного аккаунта Пользователя.
            </p>
            <p>
                9.4. Пользователь должным образом осведомлен, что Компания не берет на себя
                ответственность за какой-либо ущерб, убытки, упущенную выгоду, потерю
                деловой или личной репутации, причиненные Пользователю удалением или
                блокировкой аккаунта и/или невозможностью доступа к Сайту и его Услугам.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>10. ЗАДАТЬ ВОПРОС</strong>
            </p>
            <p>
                10.1. Если у Вас есть вопросы касательно условий настоящего Соглашения или
                порядка/способа их исполнения, Вы можете адресовать нам свой вопрос
                следующим способом:
            </p>
            <p style={{ paddingLeft: 30 }}>
                <em>
                <span id="span_id_направление_запроса">
                    отправить письмо по соответствующем адресу help@hqmranked.ru
                </span>
                </em>
            </p>
            <p>
                10.2. Сотрудники и представители Компании обязуются предпринять все
                возможные усилия для дачи ответа на Ваш запрос в течение разумного периода
                времени.
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>
                11. <span>ОТВЕТСТВЕННОСТЬ</span>
                </strong>
            </p>
            <p>
                <span>
                11.1. НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ КОМПАНИЯ ИЛИ ЕЕ ПРЕДСТАВИТЕЛИ НЕ НЕСУТ
                ОТВЕТСТВЕННОСТЬ ПЕРЕД ПОЛЬЗОВАТЕЛЕМ ИЛИ ПЕРЕД ЛЮБЫМИ ТРЕТЬИМИ ЛИЦАМИ:
                </span>
            </p>
            <p style={{ paddingLeft: 30 }}>
                <span>
                <strong>(1)</strong> ЗА ЛЮБОЙ КОСВЕННЫЙ, СЛУЧАЙНЫЙ, НЕУМЫШЛЕННЫЙ УЩЕРБ,
                ВКЛЮЧАЯ УПУЩЕННУЮ ВЫГОДУ ИЛИ ПОТЕРЯННЫЕ ДАННЫЕ, ВРЕД ЧЕСТИ, ДОСТОИНСТВУ
                ИЛИ ДЕЛОВОЙ РЕПУТАЦИИ, ВЫЗВАННЫЙ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ САЙТА, УСЛУГ ИЛИ
                ИНЫХ МАТЕРИАЛОВ, К КОТОРЫМ ПОЛЬЗОВАТЕЛЬ ИЛИ ИНЫЕ ЛИЦА ПОЛУЧИЛИ ДОСТУП С
                ПОМОЩЬЮ САЙТА, ДАЖЕ ЕСЛИ КОМПАНИЯ ПРЕДУПРЕЖДАЛА ИЛИ УКАЗЫВАЛА НА
                ВОЗМОЖНОСТЬ ТАКОГО ВРЕДА; и
                </span>
            </p>
            <p style={{ paddingLeft: 30 }}>
                <span>
                <strong>(2)</strong> ЗА ДЕЙСТВИЯ ДРУГИХ ПОЛЬЗОВАТЕЛЕЙ, ЗА РАЗМЕЩАЕМЫЙ
                ПОЛЬЗОВАТЕЛЯМИ ПОЛЬЗОВАТЕЛЬСКИЙ КОНТЕНТ, ЗА ТОВАРЫ/УСЛУГИ, ПРЕДОСТАВЛЯЕМЫЕ
                ТРЕТЬИМИ ЛИЦАМИ ИЛИ ДРУГИМИ ПОЛЬЗОВАТЕЛЯМИ (ДАЖЕ ЕСЛИ ДОСТУП К НИМ БЫЛ
                ПРЕДОСТАВЛЕН ЧЕРЕЗ НАШ САЙТ), К КОТОРЫМ ПОЛЬЗОВАТЕЛЬ ПОЛУЧИЛ ДОСТУП; и
                </span>
            </p>
            <p style={{ paddingLeft: 30 }}>
                <span>
                <strong>(3)</strong> в случаях прямо предусмотренных условиями настоящего
                Соглашения или нормой действующего законодательства.
                </span>
            </p>
            <p>
                <span>
                11.2. Наша ответственность за все что угодно, связанное с использованием
                Сайта и/или Услуг, ограничивается настолько сильно, насколько это
                допускается действующим законодательством.
                </span>
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>
                12. <span>ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</span>
                </strong>
            </p>
            <p>
                <span>
                12.1. В случае возникновения любых споров или разногласий, связанных с
                исполнением настоящего Соглашения, Пользователь и Компания приложат все
                усилия для их разрешения путем проведения переговоров между ними. В
                случае, если споры не будут разрешены путем переговоров, споры подлежат
                разрешению в порядке, установленном действующим законодательством
                Российской Федерации.
                </span>
                <br />
                <br />
            </p>
            <p style={{ paddingLeft: 30 }}>
                <strong>13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
            </p>
            <p>
                13.1. Настоящее Соглашение вступает в силу с момента его опубликования на
                Сайте (по следующей ссылке:{" "}
                <span id="span_id_ссылка_на_соглашение">hqmranked.ru/terms</span>) и
                действуют в течение неопределенного периода времени.
            </p>
            <p>
                13.2. Мы можем время от времени пересматривать, дополнять или изменять
                условия данного Соглашения. Такие изменения, как правило, не являются
                ретроспективными.{" "}
                <span
                className="texte_apparu_avec_ajax"
                style={{ display: "inline", backgroundColor: "rgb(255, 255, 255)" }}
                >
                КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО ОБЯЗАННОСТЬ ПО УВЕДОМЛЕНИЮ
                ПОЛЬЗОВАТЕЛЕЙ О ПРЕДСТОЯЩИХ ИЛИ СОСТОЯВШИХСЯ ИЗМЕНЕНИЯХ ТЕКСТА СОГЛАШЕНИЯ.
                Посредством присоединения к условиям данного Соглашения Пользователь также
                берет на себя обязательство периодически просматривать условия данного
                Соглашения на предмет их изменения или дополнения.
                </span>
            </p>
            <p>
                Если после проведенных изменений или дополнений в тексте Соглашения
                Пользователь продолжает пользоваться Сайтом, это значит, что он ознакомлен с
                изменениями или дополнениями и их принял в полном объеме без каких-либо
                возражений.
            </p>
            <p>
                13.3. Если иное прямо не указано в положениях настоящего Соглашения или
                прямо не вытекает из норм действующего законодательства, к условиям
                настоящего Договора применяется материальное право Российской Федерации.
            </p>
            <p>
                13.4. Если одно или несколько условий данного Соглашения утратило свою
                юридическую силу или признано недействительным согласно действующему
                законодательству, остальные условия Соглашения не теряют своей силы и
                продолжают действовать так, словно признанного недействительным или
                утратившим юридическую силу условия не существовало вовсе.
            </p>
            <p>
                13.5. Доступ к Сайту и его Услугам предоставляется Пользователю "как есть",
                Мы не обещаем, не гарантируем, не подразумеваем, что Услуги и Сайт могут
                подходить или не подходить Вашим потребностям, целям, ожиданиям, и в связи с
                чем не гарантируем какого-либо специфического результата или последствия в
                результате использования Вами Сайта и его Услуг.
            </p>
        </div>
    )
}

export default Terms;