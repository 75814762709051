import React from "react";
import styles from './Section_Info.module.css';
import {ReactComponent as Ice} from './img/Ice.svg';

import Avatar from './img/default_avatar.png';

const Section_Info = () => {
    const Nickname = 'Wolk'
    const Role = "Defender"


    return(
        <div className={styles.Player__Profile}>
            <div>
                <Section_Avatar />
                <div className={styles.Player__BaseInfo}>
                    <div className={styles.Player__Nickname}>{Nickname}</div>
                    <div className={styles.Player__Type}>{Role}</div>
                </div>
            </div>
            <Section_Stats />
            <Section_Position />
        </div>
    )
}

const Section_Avatar = () => {
    const roles = ["ADMIN", "PREMIUM"]
    return(
        <div className={styles.Player__Avatar}>
            <img src={Avatar} className={styles.Player__Avatar__img} alt='avatar'/>
            <div className={styles.Player__Roles}>
                {roles.map((e) => {return(<div className={`${styles["Player__Role"]} ${styles[e]}`}>{e}</div>)})}
            </div>
        </div>
    )
}

const Section_Stats = () => {
    const stats = {
        'Games': 1000,
        'Points': 7.4,
        'Goals': 1000,
        'Assists': 1000,
        'Blocks': 1000,
        'Tackles': 1000
    }

    return(
        <div className={styles.Player__Stats}>
            <div className={styles.Player__Stats__Row}>
                <div className={styles.Player__Stats__Cell}>
                    <div className={styles.Player__Stats__Name}>Games</div>
                    <div className={styles.Player__Stats__Value}>{stats['Games']}</div>
                </div>
                <div className={styles.Player__Stats__Cell}>
                    <div className={styles.Player__Stats__Name}>Points</div>
                    <div className={styles.Player__Stats__Value}>{stats['Points']}</div>
                </div>
            </div>
            <div className={styles.Player__Stats__Row}>
                <div className={styles.Player__Stats__Cell}>
                    <div className={styles.Player__Stats__Name}>Goals</div>
                    <div className={styles.Player__Stats__Value}>{stats['Goals']}</div>
                </div>
                <div className={styles.Player__Stats__Cell}>
                    <div className={styles.Player__Stats__Name}>Assists</div>
                    <div className={styles.Player__Stats__Value}>{stats['Assists']}</div>
                </div>
            </div>
            <div className={styles.Player__Stats__Row}>
                <div className={styles.Player__Stats__Cell}>
                    <div className={styles.Player__Stats__Name}>Blocks</div>
                    <div className={styles.Player__Stats__Value}>{stats['Blocks']}</div>
                </div>
                <div className={styles.Player__Stats__Cell}>
                    <div className={styles.Player__Stats__Name}>Tackles</div>
                    <div className={styles.Player__Stats__Value}>{stats['Tackles']}</div>
                </div>
            </div>
        </div>
    )
}

const Section_Position = () => {
    const Positions = {
        'C': '#4CC17B',
        'LW': '#D60000',
        'RW': '#D60000',
        'LD':'#D69A00',
        'CD':'#D69A00',
        'RD': '#4CC17B',
        'G': '#4CC17B',
    }


    return(
        <div className={styles.Player__Position}>
            <div className={styles.Player__Position__Name}>
                POSITION STATISTICS
            </div>
            <div className={styles.Player__Position__Map}>
                <Ice />
                <div className={`${styles["Player__Position__Map__On"]} ${styles['C']}`} style={{backgroundColor:Positions['C']}}></div>
                <div className={`${styles["Player__Position__Map__On"]} ${styles['LW']}`} style={{backgroundColor:Positions['LW']}}></div>
                <div className={`${styles["Player__Position__Map__On"]} ${styles['RW']}`} style={{backgroundColor:Positions['RW']}}></div>
                <div className={`${styles["Player__Position__Map__On"]} ${styles['LD']}`} style={{backgroundColor:Positions['LD']}}></div>
                <div className={`${styles["Player__Position__Map__On"]} ${styles['CD']}`} style={{backgroundColor:Positions['CD']}}></div>
                <div className={`${styles["Player__Position__Map__On"]} ${styles['RD']}`} style={{backgroundColor:Positions['RD']}}></div>
                <div className={`${styles["Player__Position__Map__On"]} ${styles['G']}`} style={{backgroundColor:Positions['G']}}></div>
            </div>
            
        </div>
    )
}

export default Section_Info;