import React, { useCallback, useEffect, useState } from "react";
import styles from './Header.module.css';
import logo from './icons/logo.svg';
import {ReactComponent as Sub} from './icons/sub.svg';

import {ReactComponent as IconYoutube} from './icons/youtube.svg';
import {ReactComponent as IconTiktok} from './icons/tiktok.svg';
import {ReactComponent as IconDiscord} from './icons/discord.svg';

import {ReactComponent as IconUser} from './icons/user.svg';

import expand from './icons/expand.svg';
import { useNavigate} from "react-router-dom";
import { useRef } from "react";

const ElHeader = () => {

    const [Logged, setLogged] = useState(true);
    const [isSubOpen, setSubOpen] = useState(false);

    const modalRef = useRef(null);

    const loginRef = useRef(null);

    const handleEmptyClick = (e) => {

        if(modalRef.current && loginRef.current && !modalRef.current.contains(e.target) && !loginRef.current.contains(e.target)){
            setSubOpen(false);
        }

    }


    useEffect(() => {
        

        document.addEventListener("click", handleEmptyClick, true)
        
        return () => {document.removeEventListener("click", handleEmptyClick, true)}
    }, [modalRef]);
    

    const navigate = useNavigate();

    return(
        <div className={styles.Header}>
            <div className={styles.Header__BG}>
                <div className={styles.Header__Left}>
                    <img className={styles.Header__Logo} src={logo} alt="logo" onClick={() => navigate("/")}/>
                    <div className={styles.Header__SiteName} onClick={() => navigate("/")}>
                        <span>HQM</span>
                        <span>RANKED</span>
                    </div>
                </div>
                <div className={styles.Header__Center}>
                    <span className={styles.Header__Center__Point}>SOLO</span>
                    <span className={styles.Header__Center__Point}>TEAM</span>
                    <span className={styles.Header__Center__Point}>БЛОГ</span>
                    <span className={styles.Header__Center__Point} onClick={() => navigate('/terms')}>ОФЕРТА</span>
                    <div className={styles.Header__Center__Sub} onClick={() => navigate('/subscription')}>
                        <Sub className={styles.Header__Sub} />
                        <span>SUBSCRIPTION TO HQM</span>
                    </div>
                </div>
                <div className={styles.Header__Right}>
                    <div className={styles.Header__Right__Social}>
                        <IconYoutube className={styles.Header__Right__Social__Icon} onClick={() => window.open('https://youtube.com/@HQMRanked')}/>
                        <IconTiktok className={styles.Header__Right__Social__Icon} onClick={() => window.open('https://www.tiktok.com/@hqmranked')}/>
                        <IconDiscord className={styles.Header__Right__Social__Icon} onClick={() => window.open('https://discord.gg/Htbrq4bN')}/>
                    </div>
                    <div className={styles.Header__Right__Divider}></div>
                    {Logged ?
                        <div ref={loginRef} className={`${styles["Header__Right__Profile__Logged"]} ${styles["Header__Right__Profile"]}`} onClick={() => setSubOpen(!isSubOpen)}>
                            <div className={styles.Header__Avatar}></div>
                            <img className={styles.Header__Expand} src={expand} alt="profile expand"/>
                        </div>
                        :
                        <div className={`${styles["Header__Right__Profile__Unlogged"]} ${styles["Header__Right__Profile"]}`} onClick={() => {navigate('/login'); setLogged(true)}}>
                            <IconUser className={styles.Header__Sub}/>
                            <span className={styles.Header__Right__Profile__Login}>LOGIN</span>
                        </div>
                    }
                </div>

                <div id="Header__Profile" ref={modalRef} className={styles.Header__Profile__Context} style={{display: (Logged && isSubOpen) ? 'grid' : 'none'}}>
                    <button className={styles.Header__Profile__Context__Point} onClick={() => navigate('profile')}>My profile</button>
                    <button className={styles.Header__Profile__Context__Point} onClick={() => navigate('settings')}>Settings</button>
                    <button className={styles.Header__Profile__Context__Point} onClick={() => setLogged(false)}>Sign Out</button>
                </div>
            </div>
        </div>
    )
}

export default ElHeader;