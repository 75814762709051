import React, {useContext, useEffect, useState} from 'react'
import { observer } from 'mobx-react-lite'
// import { context } from '../..'
import { Form, Modal } from "antd";

import Terms from '../Terms/terms'
import Privacy from '../Privacy/privacy';

import './Login.css'
import { Link } from 'react-router-dom';
import LoaderMiniMap from '../Loader/loader';
import LottieLoader from '../Loader/lottie';

import BackRequest from '../../controllers/requests';

const PageLogin = observer(() => {
    // const {rootStore} = useContext(context)

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const [modalTerms, setModalTerms] = useState(false)
    const [modalPrivacy, setModalPrivacy] = useState(false)

    const openTerms = () => {
        setModalTerms(true)
    }

    const closeTerms = () => {
        setModalTerms(false)
    }

    const openPrivacy = () => {
        setModalPrivacy(true)
    }

    const closePrivacy = () => {
        setModalPrivacy(false)
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const inputError = (id) =>{
        let Div = document.getElementById(id)
        Div.value = "Hey? I'm here!!!"
        let CuType = Div.type
        Div.type = 'text'

        let CuColor = Div.style.color
        let CuBgColor = Div.style.backgroundColor
        Div.style.color = 'rgb(255,255,255)'
        Div.style.backgroundColor = 'rgb(255,55,28)'
        sleep(1000).then(() => {
            Div.value = ""
            Div.style.color = CuColor
            Div.style.backgroundColor = CuBgColor
            Div.type = CuType
        })
    }

    const onClickHandler = () =>{
        let ExitCode = 0
        if(!email){
            inputError('Auth__email')
            ExitCode = 1
        }
        if(!password){
            inputError('Auth__Password')
            ExitCode = 1
        }

        if(ExitCode !== 0){
            console.log("Error: exit code " + ExitCode)
            return
        }

        const bodyLogin = new FormData()
        bodyLogin.append("email", email)
        bodyLogin.append("password", password)

        const Request = BackRequest('user/login', bodyLogin)

        let SubmitButton = document.getElementById('Auth__Submit')
        
        SubmitButton.value = '👉 Processing...'
        SubmitButton.style.backgroundColor = 'rgb(255,55,28)'
    }

    return(
        <div className='PAGE__Login'>
            <div className='PAGE__Login__LEFT'>
                <div className='Auth__Zone'>
                    <div className='Auth__SiteName'><img src='/logo_128.png' alt='HQMRanked Logo'/></div>
                    <div className='Auth__Welcome'>🙌 Welcome to HQMRanked</div>
                    <div className='Auth__Fields'>
                        <input name='email' placeholder='email' className='Auth__Input' id='Auth__email' onChange={(e) => setEmail(e.target.value)} type='text' autoComplete='on' autoFocus/>
                        <input name ='password' placeholder='Password' className='Auth__Input' id='Auth__Password' onChange={(e) => setPassword(e.target.value)} type='password' autoComplete='on'/>
                        <input type='submit' className='Auth__Submit' id='Auth__Submit' onClick={onClickHandler} value='SIGN IN'/>
                    </div>
                    <div className='Auth__toSignUp'>
                        <Link to='/signup'>Want to sign up?</Link>
                    </div>
                    <div className='Auth__Rules'>
                        <span>By signing up, you confirm our</span>
                        <span><input className='Input__Rules' type='submit' onClick={openTerms} value='Terms of Use'/> and <input className='Input__Rules' type='submit' onClick={openPrivacy} value='Privacy Policy'/></span>
                    </div>
                </div>
                <Modal title="Terms of use" open={modalTerms} onOk={closeTerms} onCancel={closeTerms} destroyOnClose>
                    <Terms />
                </Modal>
                <Modal title="Privacy Policy" open={modalPrivacy} onOk={closePrivacy} onCancel={closePrivacy} destroyOnClose>
                    <Privacy />
                </Modal>
            </div>
            <div className='PAGE__Login__RIGHT'>
                <div className='Loader__Zone'>
                    <LottieLoader />
                </div>
            </div>
        </div>
    )
})

export default PageLogin;