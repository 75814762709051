import React from "react";
import styles from './Profile.module.css';
import Section_Info from "./Section_Info/Section_Info";

const Profile = () => {
    return(
        <div className={styles.Page__Profile}>
            <div className={styles.Profile__Block}>
                <Section_Info />
            </div>
            <div className={styles.Profile__Block}>2</div>
            <div className={styles.Profile__Block}>3</div>
            <div className={styles.Profile__Block}>4</div>
        </div>      
    )
}

export default Profile;