import React, { useState } from 'react';
import './PreSignup.css';

import LeftPlayer from './left_player.png';
import RightPlayer from './right_player.png';
import RedSquare from './RedSquare.png';

const PreSignup = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [help, setHelp] = useState('');
  
    const handleSubmit = async (event) => {
      event.preventDefault();

      if(!name){
        let div = document.getElementById('option__nickname')
        div.classList.add('emptyerror')
        let div_label = document.getElementById('option__nickname__label')
        div_label.innerText = "HEY!!! NICKNAME"
        setTimeout(function(){
           div_label.innerHTML='Nickname';
           div.classList.remove('emptyerror')
          },2000);
      }

      if(!email){
        let div = document.getElementById('option__email')
        div.classList.add('emptyerror')
        let div_label = document.getElementById('option__email__label')
        div_label.innerText = "HEY!!! EMAIL"
        setTimeout(function(){
           div_label.innerHTML='Email';
           div.classList.remove('emptyerror')
          },2000);
      }

      if(!password){
        let div = document.getElementById('option__password')
        div.classList.add('emptyerror')
        let div_label = document.getElementById('option__password__label')
        div_label.innerText = "HEY!!! PASSWORD"
        setTimeout(function(){
           div_label.innerHTML='Password';
           div.classList.remove('emptyerror')
          },2000);
      }

      if(name && email && password){
        let button_div = document.getElementById('submit')
        button_div.classList.add('Loading')
        button_div.innerText = "Loading..."

        var help_result = 'None'
        if(help == 'option-2'){
          help_result = 'Code'
        }
        if(help == 'option-3'){
          help_result = 'Logic'
        }
        if(help == 'option-4'){
          help_result = 'Design'
        }
          // POST request using fetch with async/await
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email, password, nickname: name, help: help_result})
          };
          const response = await fetch('https://api.hqmranked.ru:5000/api/user/presignup', requestOptions);
          const data = await response.json();
          console.log(data)
          if(data == 'email+nickname'){
            let div = document.getElementById('option__email')
            div.classList.add('emptyerror')
            let div_label = document.getElementById('option__email__label')
            div_label.innerText = "Already in use"
            setTimeout(function(){
              div_label.innerHTML='Email';
              div.classList.remove('emptyerror')
              },2000);

            let div2 = document.getElementById('option__nickname')
            div2.classList.add('emptyerror')
            let div2_label = document.getElementById('option__nickname__label')
            div2_label.innerText = "Already in use"
            setTimeout(function(){
              div2_label.innerHTML='Email';
              div2.classList.remove('emptyerror')
              },2000);
            
              button_div.innerText = 'Sign Up'
          }
          else if(data == 'email'){
            let div = document.getElementById('option__email')
            div.classList.add('emptyerror')
            let div_label = document.getElementById('option__email__label')
            div_label.innerText = "Already in use"
            setTimeout(function(){
              div_label.innerHTML='Email';
              div.classList.remove('emptyerror')
              },2000);
            button_div.innerText = 'Sign Up'
          }
          else if(data == 'nickname'){
            let div = document.getElementById('option__nickname')
            div.classList.add('emptyerror')
            let div_label = document.getElementById('option__nickname__label')
            div_label.innerText = "Already in use"
            setTimeout(function(){
              div_label.innerHTML='Nickname';
              div.classList.remove('emptyerror')
              },2000);
            button_div.innerText = 'Sign Up'
          } else{
            button_div.classList.remove('Loading')
            button_div.classList.add('Success')
            button_div.innerText = 'Success!'
          }
      };      
    };
  
    return (
      <div className='PreSignup'>
        <div className="logo">
          <div className='HQM__label'>HQM</div>
          <div className='Ranked__label'>R A N K E D</div>
        </div>
        <div className="title">Hello!</div>
        <div className="subtitle">You can sign up here for new HQM experience!</div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="text-field text-field_floating" id="option__nickname">
            <input className="text-field__input" type="name" id="nickname" placeholder="Wolk" onChange={(e) => setName(e.target.value)}/>
            <label className="text-field__label" htmlFor="name" id="option__nickname__label">Nickname</label>
          </div>
          <div className="text-field text-field_floating" id="option__email">
            <input className="text-field__input" type="email" id="email" placeholder="wolchara@pochta.com" onChange={(e) => setEmail(e.target.value)}/>
            <label className="text-field__label" htmlFor="email" id="option__email__label">Email</label>
          </div>
          <div className="text-field text-field_floating" id="option__password">
            <input className="text-field__input" type="password" id="password" placeholder="******"  onChange={(e) => setPassword(e.target.value)}/>
            <label className="text-field__label" htmlFor="name" id="option__password__label">Password</label>
          </div>
          <div className="help_question">Would you like to help with something?</div>
          <div className="wrapper">
            <input type="radio" name="select" id="option-1" onClick={(e) => setHelp(e.target.id)}/>
            <input type="radio" name="select" id="option-2" onClick={(e) => setHelp(e.target.id)}/>
            <input type="radio" name="select" id="option-3" onClick={(e) => setHelp(e.target.id)}/>
            <input type="radio" name="select" id="option-4" onClick={(e) => setHelp(e.target.id)}/>
              <label htmlFor="option-1" className="option option-1">
                <div className="dot"></div>
                  <span className='option__span'>Nah</span>
              </label>
              <label htmlFor="option-2" className="option option-2">
                <div className="dot"></div>
                  <span className='option__span'>Code</span>
              </label>
              <label htmlFor="option-3" className="option option-3">
                <div className="dot"></div>
                  <span className='option__span'>Logic</span>
              </label>
              <label htmlFor="option-4" className="option option-4">
                <div className="dot"></div>
                  <span className='option__span'>Design</span>
              </label>
          </div>
          <br/>
          <button className='submit Loading' id='submit' type="submit">Sign Up</button>
        </form>

        <div className='player__icon__left'>
          <div className='player__text__left'>PLAYER</div>
          <img id="player__pic__left" src={LeftPlayer}/>
          <div className='detailed_player'>
            <img className="red_square" src={RedSquare}/>
            <div id="detailed_player__text">
              <div>DETAILED PLAYER</div>
              <div>STATISTICS</div>
            </div>
          </div>
          <div id="short_list">
              <div className="short_list_item">RATING</div>
              <div className="short_list_item">MATCHES</div>
              <div className="short_list_item">MVP</div>
              <div className="short_list_item">GOAL</div>
              <div className="short_list_item">ASSISTANT</div>
          </div>
          <div className='ggezgif'></div>
          <div className='topplayer_text'>
            <div>TOP</div>
            <div>PLAYER</div>
          </div>
        </div>
        <div className='player__icon__right'>
            <div id="right_hqm">
              <div>HOCKEY</div>
              <div>???</div>
            </div>
            <div id="right_list_1">
              <div className="short_list_item">RED TEAM</div>
              <div className="short_list_item">.</div>
              <div className="short_list_item blue">BLUE TEAM</div>
            </div>
            <div id="right_list_2">
              <div className="short_list_item">GAME SCORE</div>
              <div className="short_list_item">GOALS</div>
              <div className="short_list_item">MVP</div>
            </div>
            <div id="right_statistics">
              <img className="red_square" src={RedSquare}/>
              <div id="detailed_player__text">
                <div>STATISTICS</div>
                <div>OF ALL MATCHES</div>
              </div>
            </div>
            <div id="right_pic">
              <img id="right_pic__pic" src={RightPlayer}/>
            </div>
        </div>
      </div>
    );
  };

  export default PreSignup;